.main-services {
    display: flex;
    background-color: #F6F6F6;
    border: 1px solid #E7E7E7;
    width: 95vw;
    height: 88vh;
    border-radius: 32px;
    justify-content: center;
    align-items: center;
    position: relative; /* Add this line */
    box-sizing: border-box;
    overflow: hidden;
  }

.services-parent {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 100%; /* Ensure it takes the full height of the container */
    position: relative;
}

.services-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
    box-sizing: border-box;
    background-color: #F6F6F6;
    position: relative; /* Keep relative to maintain document flow */
    margin-top: 10px;
}


.services-title {
    font-family: 'Inter', sans-serif;
    font-size: 1em;
    color: #1C1C1C;
    font-weight: 600;
    letter-spacing: -0.05em;
    position: absolute;
    left: 50%;
    transform: translateX(-50%); /* Centers the title horizontally */

}

.services-container {
    flex-grow: 1; /* Ensure it takes up available space */
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.explainer-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 30%;
    padding-left: 34px;
    padding-top: 40px;
    padding-bottom: 50px;
}

.explainer-container {
    display: flex;
    flex-direction: column;
    font-family: 'Inter', sans-serif;
}

.t2, .t2-2 {
    font-family: 'Inter', sans-serif;
    font-size: 3em;
    color: #1C1C1C;
    font-weight: 500;
    letter-spacing: -0.05em;
    text-align: left;
    width: 100%;
}

.p1-explainer, .p1-explainer-2 {
    font-family: 'Inter', sans-serif;
    font-size: 1em;
    color: #1C1C1C;
    font-weight: 500;
    letter-spacing: -0.03em;
    width: 90%;
    text-align: left;
    margin-top: 10px;
}

.p2 {
    font-family: 'Inter', sans-serif;
    font-size: 0.9em;
    color: #1C1C1C;
    font-weight: 400;
    letter-spacing: -0.03em;
    width: 60%;
    text-align: center;
    margin-top: 10px;
}

.t3 {
    font-family: 'Inter', sans-serif;
    font-size: 1.4em;
    color: #1C1C1C;
    font-weight: 500;
    letter-spacing: -0.03em;
}

.service-icon {
    width: 70px;
    height: auto;
}

.service-square {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 32px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    min-width: 280px;
    min-height: 240px;
}


.services-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Creates 2 equal-width columns */
    grid-template-rows: repeat(2, auto); /* Creates 2 rows */
    gap: 20px; /* Adjusts space between grid items */
    padding: 40px;

    width: 60%;
}

@media only screen and (max-width: 950px) {
    .services-container {
        flex-grow: 1; /* Ensure it takes up available space */
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 30px;
    }
    .explainer-container {
        display: flex;
        flex-direction: column;
        font-family: 'Inter', sans-serif;
        align-items: center;
    }
    .explainer-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 40px;
        padding-bottom: 0px;
        gap: 40px;
        box-sizing: border-box;
    }
    .t2 {
        font-family: 'Inter', sans-serif;
        font-size: 2em;
        color: #1C1C1C;
        font-weight: 500;
        letter-spacing: -0.05em;
        text-align: center;
        width: 100%;
    }
    .t2-2 {
        font-family: 'Inter', sans-serif;
        font-size: 2em;
        color: #1C1C1C;
        font-weight: 500;
        letter-spacing: -0.05em;
        text-align: center;
        width: 100%;
    }

    .p1-explainer-2, .p1-explainer {
        font-family: 'Inter', sans-serif;
        font-size: 1em;
        color: #1C1C1C;
        font-weight: 500;
        letter-spacing: -0.03em;
        width: 80%;
        text-align: center;
        margin-top: 10px;
    }

    .main-services {
        display: flex;
        background-color: #F6F6F6;
        border: 1px solid #E7E7E7;
        width: 95vw;
        height: auto;
        border-radius: 32px;
        justify-content: center;
        align-items: center;
        position: relative; /* Add this line */
        box-sizing: border-box;
        overflow: hidden;
      }
      .services-section {
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Creates 2 equal-width columns */
        grid-template-rows: repeat(2, auto); /* Creates 2 rows */
        gap: 20px; /* Adjusts space between grid items */
        padding: 40px;
    
        width: 80%;
    }
}


@media only screen and (max-width: 767px) {
    .services-container {
        flex-grow: 1; /* Ensure it takes up available space */
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 22px;
    }
    .explainer-container {
        display: flex;
        flex-direction: column;
        font-family: 'Inter', sans-serif;
        align-items: center;
    }
    .explainer-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 40px;
        padding-bottom: 0px;
        gap: 40px;
        box-sizing: border-box;
    }
    .t2 {
        font-family: 'Inter', sans-serif;
        font-size: 2em;
        color: #1C1C1C;
        font-weight: 500;
        letter-spacing: -0.05em;
        text-align: center;
        width: 100%;
    }
    .t2-2 {
        font-family: 'Inter', sans-serif;
        font-size: 2em;
        color: #1C1C1C;
        font-weight: 500;
        letter-spacing: -0.05em;
        text-align: center;
        width: 100%;
    }

    .p1-explainer-2, .p1-explainer {
        font-family: 'Inter', sans-serif;
        font-size: 1em;
        color: #1C1C1C;
        font-weight: 500;
        letter-spacing: -0.03em;
        width: 80%;
        text-align: center;
        margin-top: 10px;
    }

    .main-services {
        display: flex;
        background-color: #F6F6F6;
        border: 1px solid #E7E7E7;
        width: 95vw;
        height: auto;
        border-radius: 32px;
        justify-content: center;
        align-items: center;
        position: relative; /* Add this line */
        box-sizing: border-box;
        overflow: hidden;
      }
      .services-section {
        display: grid;
        grid-template-columns: repeat(1, 1fr); /* Creates 2 equal-width columns */
        gap: 20px; /* Adjusts space between grid items */
        padding: 0px;
        justify-content: center;
        align-items: center;
        width: 90%;
        padding-top: 40px;
    }
    .service-square {
        display: flex;
        flex-direction: column;
        background-color: #ffffff;
        border-radius: 32px;
        justify-content: center;
        align-items: center;
        gap: 5px;
        min-width: 280px;
        min-height: 300px;
    }
  }