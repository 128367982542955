.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
}

body {
  font-family: 'Inter', sans-serif;

}



.nlmlogo {
  display: flex;
  width: 12px;
  height: auto;
}

.navbar {
  display: flex;
  position: relative;
  width: 100%;
  height: 60px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  letter-spacing: -0.05em;
}

.NLM {
  font-family: 'Inter', sans-serif;
  font-size: 1em;
  color: #1C1C1C;
  font-weight: 600;
}

.Studio {
  font-family: 'Inter', sans-serif;
  font-size: 1em;
  color: #1C1C1C;
  font-weight: 300;
}

.main {
  display: flex;
  background-color: #F6F6F6;
  border: 1px solid #E7E7E7;
  width: 95vw;
  height: 88vh;
  border-radius: 32px;
  justify-content: center;
  align-items: center;
  position: relative; /* Add this line */
  box-sizing: border-box;
  overflow: hidden;
}

.secondary-btn {
  font-family: 'Inter', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #0D0D0D;
  color: #1C1C1C;
  font-size: 0.8em;
  font-weight: 600;
  letter-spacing: -0.03em;
  border-radius: 90px;
  padding: 10px 20px;
  background-color: rgba(240, 255, 255, 0);
  transition: all 0.4s ease-in-out;
}

.secondary-btn:hover, .primary-btn:hover {
  padding: 10px 16px;
}

.primary-btn {
  font-family: 'Inter', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1C1C1C;
  color: #F2F2F2;
  font-size: 0.8em;
  font-weight: 600;
  letter-spacing: -0.03em;
  border-radius: 90px;
  border: none;
  padding: 10px 20px;
  box-shadow: inset 0px 2px 6px rgba(255, 255, 255, 0.4), 2px 3px 10px rgba(0, 0, 0, 0.3);
  transition: all 0.4s ease-in-out;
}

.works {
  display: flex;
  margin-top: 20px;
  width: 95vw;
  height: 40vh;
  border-radius: 32px;
  justify-content: left; /* Ensure children are spaced correctly */
  align-items: center; /* Center items vertically */
  gap: 20px;
  box-sizing: border-box;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  scroll-behavior: smooth;
}

.works-item {
  flex-shrink: 0;
  flex-grow: 0;
  height: 100%;
  width: auto;
  border-radius: 32px;
  border: 1px solid #E7E7E7;
  box-sizing: border-box;
}

@media only screen and (max-width: 767px) {
  .primary-btn, .secondary-btn {
    padding: 10px 14px;
  }
  .main {
    display: flex;
    background-color: #F6F6F6;
    border: 1px solid #E7E7E7;
    width: 95vw;
    height: 79vh;
    border-radius: 32px;
    justify-content: center;
    align-items: center;
    position: relative; /* Add this line */
    box-sizing: border-box;
    overflow: hidden;
  }

}