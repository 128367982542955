.home-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    z-index: 1;
}

.main video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    transform: translate(-50%, -50%);
    object-fit: contain;
    opacity: 0.3; /* Optional: Adjust the opacity as needed */
  
}

.t1 {
    font-family: 'Inter', sans-serif;
    font-size: 4em;
    color: #1C1C1C;
    font-weight: 400;
    letter-spacing: -0.05em;
    width: 60%;
}

.light-grey-text {
    font-family: 'Inter', sans-serif;
    color: #9E9E9E;
}

.p1 {
    font-family: 'Inter', sans-serif;
    font-size: 1em;
    color: #1C1C1C;
    font-weight: 500;
    letter-spacing: -0.03em;
    width: 60%;
}

.buttons {
    display: flex;
    gap: 10px;
    margin-top: 1vh;
}



.recent-works-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    position: absolute;
    bottom: 2vh;
}

.recent-works-txt {
    font-family: 'Inter', sans-serif;
    font-size: 0.8em;
    color: #1C1C1C;
    font-weight: 600;
    letter-spacing: -0.05em;

}

.arrow {
    width: 20px;
    height: auto;
}

@media only screen and (max-width: 950px) {
    .t1 {
        font-family: 'Inter', sans-serif;
        font-size: 3em;
        color: #1C1C1C;
        font-weight: 400;
        letter-spacing: -0.05em;
        width: 80%;
    }
  }

@media only screen and (max-width: 767px) {
    .t1 {
        font-family: 'Inter', sans-serif;
        font-size: 2em;
        color: #1C1C1C;
        font-weight: 400;
        letter-spacing: -0.05em;
        width: 80%;
    }
    .main video {
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        z-index: 0;
        transform: translate(-50%, -50%);
        object-fit: contain;
        opacity: 0.45; /* Optional: Adjust the opacity as needed */
      
    }
  }